<template>
  <StyledModal :visible="visible" :title="`${isAdd ? '新建' : '编辑'}消息通知`" @close="close" @submit="submit" :submitLoading="submitLoading">
    <el-form class="form_box" ref="formRef" :model="formModel.form" label-width="120px">
      <el-form-item label="通知标题" prop="noticeTitle" :rules="[{ required: true, message: '请输入通知标题' }]">
        <el-input v-model="formModel.form.noticeTitle" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="通知封面" prop="noticeImage" :rules="[{ required: true, message: '请添加通知封面' }]">
        <ImgUpload v-model="formModel.form.noticeImage" :limit="1" />
      </el-form-item>
      <el-form-item label="通知内容" prop="noticeContent" :rules="[{ required: true, message: '请添加通知内容' }]">
        <DetailEditor v-model="formModel.form.noticeContent" />
      </el-form-item>
    </el-form>
  </StyledModal>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { cloneDeep } from 'lodash'
import StyledModal from '@/components/modal/StyledModal'
import ImgUpload from '@/components/ImgUpload'
import FetchSelect from '@/components/FetchSelect'
import DetailEditor from '@/components/DetailEditor'
import { validateFormRef } from '@/common/utils_element'
import request from '@/utils/request'

const defaultForm = {
  noticeTitle: '',
  noticeImage: '',
  noticeContent: '',
}

export default {
  emits: ['successAdd', 'successEdit'],
  setup(_, ctx) {
    const visible = ref(false)
    const submitLoading = ref(false)

    const formRef = ref(null)
    const formModel = reactive({
      form: cloneDeep(defaultForm),
    })

    const record = reactive({ v: null })
    const isAdd = computed(() => (record.v ? false : true))

    function open(recordParam) {
      visible.value = true
      if (recordParam) {
        record.v = cloneDeep(recordParam)
        renderForm(cloneDeep(recordParam))
      }
    }

    function close() {
      visible.value = false
      record.v = null
      formModel.form = cloneDeep(defaultForm)
    }

    // form回显
    function renderForm(data) {
      // 处理noticeContent // 因为saas商城和在线问诊 这俩项目的 这个组件，数据格式不一样
      const arr = JSON.parse(data.noticeContent ?? '[]')
      const noticeContentArr = arr.map((item) => {
        let type
        if (item.type == 1) {
          type = 'text'
        } else if (item.type == 2) {
          type = 'image'
        }
        return {
          id: item.id,
          type,
          content: item.value,
        }
      })
      const noticeContent = JSON.stringify(noticeContentArr)
      // 处理noticeContent end

      formModel.form = {
        ...data,
        noticeContent,
      }
    }

    // 提交
    async function submit() {
      await validateFormRef(formRef)

      // 处理noticeContent // 因为saas商城和在线问诊 这俩项目的 这个组件，数据格式不一样
      const arr = JSON.parse(formModel.form.noticeContent ?? '[]')
      const noticeContentArr = arr.map((item) => {
        let type
        if (item.type == 'text') {
          type = 1
        } else if (item.type == 'image') {
          type = 2
        }
        return {
          id: item.id,
          type,
          value: item.content,
        }
      })
      const noticeContent = JSON.stringify(noticeContentArr)
      // 处理noticeContent end

      const postData = {
        ...(isAdd.value ? {} : { noticeCode: record.v.noticeCode }),
        ...formModel.form,
        noticeContent,
      }
      submitLoading.value = true
      request({
        url: isAdd.value ? '/web/system/notice/createNotice' : '/web/system/notice/updateNotice',
        data: postData,
      })
        .finally(() => {
          submitLoading.value = false
        })
        .then(() => {
          ctx.emit(isAdd.value ? 'successAdd' : 'successEdit')
          close()
        })
    }

    return {
      visible,
      isAdd,
      submitLoading,
      open,
      close,
      submit,
      //
      formRef,
      formModel,
    }
  },
  components: { StyledModal, FetchSelect, ImgUpload, DetailEditor },
}
</script>

<style lang="less" scoped>
.form_box {
  width: 90%;
}
</style>
